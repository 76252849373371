// React
import React from 'react';
import Helmet from 'react-helmet';

// Gatsby
import { Link, graphql } from 'gatsby';
// import Img from 'gatsby-image';

// Third-party
import get from 'lodash/get';
import moment from 'moment';

// Core components
import Layout from '../components/layout';
//import heroStyles from '../components/hero.module.css';

class EventTemplate extends React.Component {
  render() {
    const event = get(this.props, 'data.contentfulEvent');
    const runs = get(this.props, 'data.allContentfulDragstripRun.edges');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff', color: '#373f49' }}>
          <Helmet title={`${event.name} | ${siteTitle}`} />

          {/* <div className={heroStyles.hero}>
            {event.name} */}
          {/* <Img className={heroStyles.heroImage} alt={event.name} fluid={post.heroImage.fluid} /> */}
          {/* </div> */}
          <div className="wrapper">
            <h1 className="section-headline">{event.name}</h1>
            <div>Click on a run # to see the details.</div>
            <table style={{ width: '100%', marginTop: '8px' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', width: '60px' }}>Run #</th>
                  <th style={{ textAlign: 'left', width: '190px' }}>Date/time</th>
                  <th style={{ textAlign: 'right', width: '80px' }}>RT</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>60'</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/8 ET</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/8 MPH</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/4 ET</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/4 MPH</th>
                  <th style={{ textAlign: 'right', width: '75px' }}>DA</th>
                  <th style={{ textAlign: 'right', width: '80px' }}>Temp</th>
                  <th style={{ textAlign: 'right' }}>Launch RPM</th>
                </tr>
              </thead>
              <tbody>
                {runs.map(({ node }) => {
                  return (
                    <tr key={node.slug}>
                      <td>
                        <Link to={`/run/${node.slug}`}>{node.round}</Link>
                      </td>
                      <td>{moment(node.dateTime).format('MM/DD/YYYY hh:mm:ss A')}</td>
                      <td style={{ textAlign: 'right' }}>{node.reactionTime.toFixed(4)}</td>
                      <td style={{ textAlign: 'right' }}>{node.sixtyFootET.toFixed(4)}</td>
                      <td style={{ textAlign: 'right' }}>{node.eighthMileET.toFixed(4)}</td>
                      <td style={{ textAlign: 'right' }}>{node.eighthMileMPH.toFixed(2)}</td>
                      <td style={{ textAlign: 'right' }}>
                        {node.quarterMileET ? node.quarterMileET.toFixed(4) : '-'}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {node.quarterMileMPH ? node.quarterMileMPH.toFixed(2) : '-'}
                      </td>
                      <td style={{ textAlign: 'right' }}>{node.densityAltitude}</td>
                      <td style={{ textAlign: 'right' }}>{node.temperature}</td>
                      <td style={{ textAlign: 'right' }}>{node.launchRpm}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div style={{ margin: '20px 4px 0 4px' }}>Notes:</div>
            <div style={{ margin: '4px', border: '1px solid gray', padding: '0 10px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: event.eventNotes.childMarkdownRemark.html,
                }}
              />
            </div>
            <div style={{ margin: '10px 4px 0 4px' }}>Mods:</div>
            <div style={{ margin: '4px', border: '1px solid gray', padding: '0 10px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: event.mods.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EventTemplate;

export const pageQuery = graphql`
  query EventBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulEvent(slug: { eq: $slug }) {
      name
      eventDate(formatString: "MMMM Do, YYYY")
      track {
        slug
        name
      }
      eventNotes {
        childMarkdownRemark {
          html
        }
      }
      mods {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulDragstripRun(
      filter: { event: { slug: { eq: $slug } } }
      sort: { fields: [dateTime], order: ASC }
    ) {
      edges {
        node {
          slug
          round
          dateTime
          reactionTime
          sixtyFootET
          eighthMileET
          eighthMileMPH
          quarterMileET
          quarterMileMPH
          densityAltitude
          temperature
          launchRpm
        }
      }
    }
  }
`;
